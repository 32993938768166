import { take } from "lodash-es";
import HubDbHelper from "../Helpers/HubDbHelper";
import ko from "../Library/Knockout";
import PageContext from "./PageContext";

export default class ReviewWidget {

    get displayCountryCode() {
        if (this.shouldFilterByCountryCode != null) {
            const country = this.pageContext.countries
                .find(c => c.countryCode == this.shouldFilterByCountryCode);
            return country?.countryCode;
        }

        return this.pageContext.contentCountryCode;
    }

    constructor(reviews, headerText, shouldFilterByCountryCode) {
        this.pageContext = new PageContext();
        this.shouldFilterByCountryCode = shouldFilterByCountryCode;
        this.autoScrollDelay = 8000;
        this.autoScroll = ko.observable(true);
        this.reviewContainerMaxHeight = ko.observable("0px");
        this.selectedIndex = ko.observable(0);
        this.headerText = ko.observable(headerText);
        this.maxReviews = 5;
        this.allReviews = ko.observable([]);
        HubDbHelper.getTableRows('reviews')
            .then(reviews => this.allReviews(reviews));
        this.reviews = ko.pureComputed(() => this.filterReviews(this.allReviews()));

        this.autoScrollInterval = setInterval(() => {
            if (!this.autoScroll())
                return clearInterval(this.autoScrollInterval);
            var nextIndex = this.selectedIndex() + 1;
            var max = this.reviews().length;
            this.setSelected(nextIndex == max ? 0 : nextIndex)();
        }, this.autoScrollDelay);

    }

    setSelected(index) {
        return (review, e) => {
            this.selectedIndex(index);
            if (e) this.autoScroll(false);
            this.reviews().map((r, i) => {
                r.selected(index == i);
            });
        };
    }

    filterReviews(reviews) {
        if (!reviews) return [];

        const filtered = reviews
            .filter(review => {
                if (!this.shouldFilterByCountryCode) return true;
                return review.countryCode?.find(cc => cc?.name == this.displayCountryCode);
            })
            .map((review, i) => new Review(review, i == 0));

        return take(filtered, this.maxReviews);
    }


}
class Review {
    constructor(reviewData, selected = false) {
        this.image = "";
        this.reviewerCompany = "";
        this.reviewerLocation = "";
        this.visible = true;
        Object.assign(this, reviewData);
        this.selected = ko.observable(selected);
        this.showStars = true;
    }
}