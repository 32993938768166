import ko from "./Library/Knockout";
import { get } from "lodash-es";
import "../style/Website/Home.less";

import App from "./Components/App";
import ReviewWidget from "./Components/ReviewWidget";

const app = new App();

const reviews = get(window, "tradify.reviews", []);
const countryCode = get(window, "tradify.currentCountry.countryCode", []);

app.reviews = new ReviewWidget(reviews, null, countryCode);

ko.applyBindings(app);