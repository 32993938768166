import axios from 'axios';
import PageContext from '../Components/PageContext';
import camelCaseObject from './../Functions/camelCaseObject';

export default class HubDbHelper {
    static async getTableRows(tableName) {
        const context = new PageContext();
        const config = {
            method: 'get',
            url: `https://api.hubapi.com/cms/v3/hubdb/tables/${tableName}/rows?portalId=${context.portalId}`,
            headers: {}
        };

        return axios(config)
            .then(res => res.data?.results?.map(row => camelCaseObject(row.values)));
    }
}